import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  loadProfile,
  setlocked,
  signout,
  updatepayment,
  updatesignup,
} from "../actions/userActions";
import ModifyButtons from "../components/ModifyButtons";
import MUIDataTable from "mui-datatables";
import { IconButton, Stack } from "@mui/material";
import { downloadDoc } from "../utils/docs";
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { loadParams } from "../actions/paramActions";
import { loadPricelist } from "../utils/utils";

export default function SignupScreen() {
  const [curId, setCurId] = useState(0);

  const [modSigup, setModSigup] = useState(null);
  const [modPayment, setModPayment] = useState(null);

  const [locked, setLocked] = useState(true);
  const [lockedby, setLockedby] = useState(null);
  const [sigyears, setSigyears] = useState([]);

  const [payments, setPayments] = useState([]);
  const [wiretransfers, setWiretransfers] = useState([]);

  const [genpricelist, setGenPricelist] = useState([]);
  const [pricelist, setPricelist] = useState([]);

  const [docError, setDocError] = useState(null);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const paramList = useSelector((state) => state.paramList);
  const { parameters, signupTypes } = paramList;

  let params = useParams();

  const userDetails = useSelector((state) => state.userDetails);
  const { userProfile, loading, error, message } = userDetails;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handlePaymentEdit(type, pay_id) {
    dispatch(updatepayment(userProfile.id, type, { paymentId: pay_id }));
  }

  function handleWiretransferEdit(type, wt_id) {
    dispatch(updatepayment(userProfile.id, type, { wtId: wt_id }));
  }

  function handleAddPayment(event) {
    const valid = event.currentTarget.form.reportValidity();
    if (!valid) return;

    dispatch(
      updatepayment(userProfile.id, "add", {
        year: userProfile.assocyear,
        euro: modPayment.euro,
        when: moment(modPayment.whenD).format("DD/MM/YYYY"),
        comment: modPayment.comment,
        extra: modPayment.extra,
      })
    );
  }

  async function handleDownload(profile, type, doc) {
    const ret = await downloadDoc(
      profile,
      type,
      doc.id,
      doc.description,
      doc.when2,
      doc.ext
    );

    setDocError(ret?.error);
    if (ret?.error) {
      console.log("cannot download document", ret.error);
      if (ret.exit) dispatch(signout());
    }
  }

  function renderActions(value, { rowIndex }, updateValue) {
    return locked || payments[rowIndex].id === undefined ? null : (
      <div align="center">
        <IconButton
          onClick={() => handlePaymentEdit("delete", payments[rowIndex].id)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  }

  const paymentColumns = [
    { name: "when", label: "Data" },
    { name: "comment", label: "Commento" },
    {
      name: "euro",
      label: "Importo",
    },
    {
      name: "insertedby",
      label: "Inserito da",
    },
    { name: "extra", label: "Altro" },
    {
      name: "actions",
      label: "Azioni",
      options: { customBodyRender: renderActions },
    },
  ];

  function renderWtActions(value, { rowIndex, columnIndex }, updateValue) {
    return (
      <Stack direction="row">
        <IconButton
          onClick={() =>
            handleWiretransferEdit("deletewt", wiretransfers[rowIndex].id)
          }
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          onClick={() =>
            handleDownload(
              userProfile,
              "wiretransfers",
              wiretransfers[rowIndex]
            )
          }
        >
          <VisibilityIcon />
        </IconButton>
      </Stack>
    );
  }

  const wiretransferColumns = [
    { name: "when", label: "Data" },
    { name: "description", label: "Descrizione" },
    {
      name: "euro",
      label: "Importo",
    },
    { name: "reference", label: "Riferimento" },
    {
      name: "actions",
      label: "Azioni",
      options: { customBodyRender: renderWtActions },
    },
  ];

  const restoreUserProfileState = useCallback(() => {
    // TODO: messaggio di errore
    if (!userProfile.canManage) return;

    setModSigup({ ...userProfile });

    let payms = [];

    if (userProfile?.payments && userProfile.payments.length > 0) {
      const paid = userProfile?.payments.reduce(
        (sum, p) => (sum += parseInt(p.euro)),
        0
      );
      payms = [
        ...userProfile?.payments,
        { comment: "Totale versato", euro: paid },
        {
          comment: "Importo residuo",
          euro: parseInt(userProfile.assoceuro) - paid,
        },
      ];
    }

    setPayments(payms);

    setWiretransfers([...userProfile?.wiretransfers]);

    // anni
    let yy = [];
    const minyy =
      parseInt(userProfile?.assocyear) || parseInt(userInfo?.currentYear);
    const maxyy = parseInt(userInfo?.currentYear);
    for (var y = minyy; y <= maxyy; y++) {
      yy.push(y);
    }
    setSigyears(yy);
  }, [userProfile, userInfo?.currentYear]);

  //TODO: eliminare curId
  useEffect(() => {
    let newId = params && params.id ? params.id : userInfo.id;
    setCurId(newId);
  }, [userInfo, userProfile, params]);

  useEffect(() => {
    if (curId !== 0 && (!userProfile || userProfile.id !== curId)) {
      dispatch(loadProfile(curId));
    }

    if (userProfile && userProfile.id === curId) {
      restoreUserProfileState();

      setLocked(userProfile.locked);

      setLockedby(
        userProfile.lockedbyusr != null ? userProfile.lockedbyusr : null
      );
    }
  }, [userInfo, userProfile, dispatch, curId, restoreUserProfileState]);

  useEffect(() => {
    if (!parameters?.length) {
      dispatch(loadParams());
    }
  }, [dispatch, parameters]);

  const onEditUser = async (event) => {
    event.preventDefault();

    if (!locked) {
      // restore data
      restoreUserProfileState();
      setModPayment(null);
    } else {
      const d = new Date();
      setModPayment({
        euro: 0,
        whenD: d,
        comment: "",
        extra: "",
      });
    }

    const plobject = await loadPricelist();
    if (plobject !== false) {
      setGenPricelist(plobject.rules);
      setPricelist(plobject.items);
    }

    dispatch(setlocked(userProfile.id, locked));
  };

  const onUnlockProfile = () => {
    dispatch(setlocked(userProfile.id, false));
  };

  const onUpdateSigup = (event) => {
    const valid = event.currentTarget.form.reportValidity();
    if (!valid) return;

    const newSigup = {
      userId: userProfile.id,
      ...modSigup,
    };

    dispatch(updatesignup(userProfile.id, newSigup));
    dispatch(setlocked(userProfile.id, false));
  };

  const onChangeSigupParam = (param, value, type = "text") => {
    var val = value;
    if (type === "checkbox") {
      val = value ? 1 : 0;
    }
    setModSigup({ ...modSigup, [param]: val });
  };

  function calcFingerprint(type, newtype) {
    switch (type) {
      case "category":
        return userProfile.category;
      case "location":
        return userProfile.location;
      case "signup":
        return newtype;
      case "age":
        return userProfile.age < 16 ? "agelt16" : "ageother";
      case "numtrainings":
        return "tr" + userProfile.numtrainings;
      default:
        break;
    }

    return "?";
  }

  const onChangeAssoctype = (type) => {
    let fingerprint = "";
    genpricelist.forEach((gpl) => {
      if (fingerprint === "" || fingerprint === gpl.fingerprint) {
        const fp = calcFingerprint(gpl.type, type);
        fingerprint = fingerprint === "" ? fp : fingerprint + "_" + fp;
      }
    });

    const idx = pricelist.findIndex((pl) => pl.fingerprint === fingerprint);
    const euro = idx < 0 ? 0 : pricelist[idx].value;

    setModSigup({ ...modSigup, assoctype: type, assoceuro: euro });
  };

  const onChangePaymentParam = (param, value, type = "text") => {
    var val = value;
    if (type === "checkbox") {
      val = value ? 1 : 0;
    }
    setModPayment({ ...modPayment, [param]: val });
  };

  return (
    <>
      {loading && <LinearProgress />}
      {docError && <Alert severity="error">Documento non trovato</Alert>}
      {message && <Alert severity="info">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      {modSigup && (
        <Box component="form" autoComplete="off">
          <Grid container spacing={2} padding={2}>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label="Cognome"
                  value={userProfile.surname}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label="Nome"
                  value={userProfile.name}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth>
                <TextField
                  label="Data di nascita"
                  value={userProfile.birthdate}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            {userProfile.uispexpire !== "NA" && (
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Scadenza Tessera UISP"
                    value={userProfile.uispexpire}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </Grid>
            )}
            {userProfile.fidalexpire !== "NA" && (
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Scadenza Tessera FIDAL"
                    value={userProfile.fidalexpire}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </Grid>
            )}
            {(userProfile.category === "J" ||
              userProfile.category === "JB") && (
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Numero allenamenti"
                    value={userProfile.numtrainings}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="h5">Dati iscrizione</Typography>
            </Grid>

            <ModifyButtons
              locked={locked}
              lockedby={lockedby}
              onEditUser={onEditUser}
              onUnlockProfile={onUnlockProfile}
              onUpdateProfile={onUpdateSigup}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(`/profile/${userProfile.id}`)}
              >
                Dati atleta
              </Button>
            </ModifyButtons>

            <Grid item xs={12}>
              <Typography>
                {userProfile.assocyear === ""
                  ? "Nessuna iscrizione presente"
                  : "Cambia anno per inserire nuova iscrizione"}
              </Typography>
            </Grid>

            <Grid item xs={4} md={2}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="assocyear">
                  Periodo di riferimento
                </InputLabel>
                <Select
                  id="assocyear"
                  label="Periodo di riferimento"
                  value={modSigup.assocyear > 0 ? modSigup.assocyear : ""}
                  disabled={locked}
                  onChange={(e) =>
                    onChangeSigupParam("assocyear", parseInt(e.target.value))
                  }
                >
                  {sigyears.map((yy) => (
                    <MenuItem key={yy} value={yy}>
                      {yy}/{(yy + 1) % 100}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} md={2}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="assoctype">Tipo iscrizione</InputLabel>
                <Select
                  id="assoctype"
                  label="Tipo iscrizione"
                  value={modSigup.assoctype}
                  disabled={locked}
                  onChange={(e) => onChangeAssoctype(e.target.value)}
                  //required
                >
                  {signupTypes?.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  id="assoccomment"
                  label="Note"
                  type="text"
                  value={modSigup.assoccomment}
                  variant="standard"
                  disabled={locked}
                  onChange={(e) =>
                    onChangeSigupParam("assoccomment", e.target.value)
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormControl fullWidth>
                <TextField
                  id="assoceuro"
                  label="Importo totale"
                  type="text"
                  value={modSigup.assoceuro}
                  variant="standard"
                  disabled={locked}
                  onChange={(e) =>
                    onChangeSigupParam("assoceuro", e.target.value)
                  }
                  required
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      )}
      {(payments === null || payments.length === 0) && (
        <Box>Nessun pagamento</Box>
      )}
      {payments && payments.length > 0 && (
        <>
          <Box margin={2} flex={true} flexGrow="1">
            <MUIDataTable
              title="Pagamenti"
              data={payments}
              columns={paymentColumns}
              options={{
                selectableRowsHideCheckboxes: true,
                pagination: false,
                download: false,
                print: false,
                viewColumns: false,
                filter: false,
                search: false,
                sort: false,
              }}
            />
          </Box>
        </>
      )}
      {modPayment &&
        userProfile?.assocyear > 0 &&
        userProfile.assoctype !== "" && (
          <Box component="form" autoComplete="off">
            <Grid container spacing={2} padding={2}>
              <Grid item xs={4} md={3}>
                <FormControl fullWidth>
                  <MobileDatePicker
                    inputFormat="DD/MM/YYYY"
                    id="paywhen"
                    label="Data"
                    type="text"
                    value={modPayment.whenD}
                    onChange={(value) => onChangePaymentParam("whenD", value)}
                    required
                    renderInput={(params) => (
                      <TextField variant="standard" {...params} />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} md={5}>
                <FormControl fullWidth>
                  <TextField
                    id="paycomment"
                    label="Commento"
                    type="text"
                    value={modPayment.comment}
                    variant="standard"
                    onChange={(e) =>
                      onChangePaymentParam("comment", e.target.value)
                    }
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} md={2}>
                <FormControl fullWidth>
                  <TextField
                    id="payeuro"
                    label="Importo"
                    type="text"
                    value={modPayment.euro}
                    variant="standard"
                    onChange={(e) =>
                      onChangePaymentParam("euro", e.target.value)
                    }
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} md={2}>
                <FormControl fullWidth>
                  <TextField
                    id="payextra"
                    label="Altro"
                    type="text"
                    value={modPayment.extra}
                    variant="standard"
                    onChange={(e) =>
                      onChangePaymentParam("extra", e.target.value)
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => handleAddPayment(event)}
                >
                  Aggiungi
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      {(wiretransfers === null || wiretransfers.length === 0) && (
        <Box>Nessun Bonifico</Box>
      )}
      {wiretransfers && wiretransfers.length > 0 && (
        <>
          <Box margin={2} flex={true} flexGrow="1">
            <MUIDataTable
              title="Bonifici"
              data={wiretransfers}
              columns={wiretransferColumns}
              options={{
                selectableRowsHideCheckboxes: true,
                pagination: false,
                download: false,
                print: false,
                viewColumns: false,
                filter: false,
                search: false,
                sort: false,
              }}
            />
          </Box>
        </>
      )}
    </>
  );
}

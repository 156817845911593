import axiosInstance, { manageReason } from "../actions/axios";
import { forceReload } from "../actions/docActions";
import { publicName, siteName, siteUrl } from "./config";
import { getEmailAdmin } from "./utils";

// TODO: docHead
export async function downloadDoc(
  userProfile,
  docType,
  docId,
  docDescription,
  docDate,
  docExt
) {
  return await axiosInstance
    .post(
      "/users/getdoc.php",
      { userId: userProfile.id, docType, docId },
      { responseType: "blob" }
    )
    .then((response) => {
      //Create a Blob from the PDF Stream
      const filetype = response.headers["content-type"];
      //console.log("h", response.headers, filetype);
      const file = new Blob([response.data], { type: filetype });
      const fileName = response.headers["content-disposition"]
        ? response.headers["content-disposition"]
            .split("filename=")[1]
            .split(";")[0]
        : `${userProfile.surname}_${userProfile.name}_${docDescription}_${docDate}.${docExt}`;
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);

      // simulate a ref click to download
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = fileURL;
      a.download = fileName;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(fileURL);
        document.body.removeChild(a);
      }, 0);

      return { error: false };
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      return { error: message, exit };
    });
}

export function uploadDoc(
  userId,
  discipline,
  fileUpload,
  dispatch,
  onUploadProgress
) {
  const url = siteUrl + "/documents/" + fileUpload.docType;

  let formData = new FormData();
  formData.append("docDate", fileUpload.docDate);
  formData.append("docType", fileUpload.docType);
  formData.append("subDocType", fileUpload.subDocType);
  formData.append("toBeSigned", fileUpload.toBeSigned ? 1 : 0);
  formData.append("docDescription", fileUpload.docDescription);
  formData.append("docEuro", fileUpload.docEuro);
  formData.append("docRef", fileUpload.docRef);
  formData.append("userId", userId);
  formData.append("from", publicName);
  formData.append("emailadmin", getEmailAdmin(discipline));
  formData.append("url", url);
  formData.append("file", fileUpload.currentFile);

  dispatch(forceReload());

  return axiosInstance.post("/users/uploaddoc.php", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
}

export function uploadModule(fileUpload, onUploadProgress) {
  let formData = new FormData();
  formData.append("request", "add");
  formData.append("docKey", fileUpload.docKey);
  formData.append("docDescription", fileUpload.docDescription);
  formData.append("isInfo", fileUpload.isInfo);
  formData.append("file", fileUpload.currentFile);

  return axiosInstance.post("/modules.php", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
}

// export function downloadPdf(userProfile, { empty }) {
//   axiosInstance
//     .get(`/users/getpdf.php?usrid=${userProfile.id}&empty=${empty}`, {
//       responseType: "blob",
//     })
//     .then((response) => {
//       //Create a Blob from the PDF Stream
//       const filetype = response.headers["content-type"];
//       const file = new Blob([response.data], { type: filetype });
//       let fileName = response.headers["content-disposition"]
//         ? response.headers["content-disposition"]
//             .split("filename=")[1]
//             .split(";")[0]
//         : empty
//         ? `MODULO_VUOTO.pdf`
//         : `MODULO_${userProfile.surname}_${userProfile.name}.pdf`;
//       // remove "
//       if (fileName.startsWith('"')) fileName = fileName.slice(1);
//       if (fileName.endsWith('"')) fileName = fileName.slice(0, -1);

//       //Build a URL from the file
//       const fileURL = URL.createObjectURL(file);

//       // simulate a ref click to download
//       const a = document.createElement("a");
//       document.body.appendChild(a);
//       a.href = fileURL;
//       a.download = fileName;
//       a.click();
//       setTimeout(() => {
//         window.URL.revokeObjectURL(fileURL);
//         document.body.removeChild(a);
//       }, 0);

//       return { error: false };
//     })
//     .catch((reason) => {
//       const { message, exit } = manageReason(reason);
//       return { error: message, exit };
//     });
// }

export async function downloadDBBackup() {
  return await axiosInstance
    .get("/dbBackup.php", { responseType: "blob" })
    .then((response) => {
      const file = new Blob([response.data], { type: "application/text" });
      const date = new Date().toISOString().slice(0, 10);
      const fileName = `Backup_Polisportiva_${siteName}_${date}.sql`;
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);

      // simulate a ref click to download
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = fileURL;
      a.download = fileName;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(fileURL);
        document.body.removeChild(a);
      }, 0);

      return { error: false };
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      return { error: message, exit };
    });
}

export function uploadBannerImage(fileUpload, onUploadProgress) {
  let formData = new FormData();
  formData.append("request", "add");
  formData.append("file", fileUpload.currentFile);

  return axiosInstance.post("/banner.php", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
}

import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCatasto } from "../actions/catastoActions";

export default function BirthPlace({ modUser, locked, onChangeUserParam }) {
  const [birthProvs, setBirthProvs] = useState(["-"]);
  const [birthLocs, setBirthLocs] = useState(["-"]);

  const catasto = useSelector((state) => state.catasto);
  const { provs_cities, loading, error } = catasto;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!provs_cities?.length) dispatch(loadCatasto());

    // from array find unique provs
    const provs = provs_cities
      .map((i) => i.prov)
      .filter((prov, idx, ar) => ar.indexOf(prov) === idx)
      .sort((a, b) => (a > b ? 1 : -1));

    setBirthProvs(provs);
  }, [dispatch, provs_cities]);

  const updateCities = (prov) => {
    onChangeUserParam("birthloc", "-");
    onChangeUserParam("birthprov", prov);

    // from prov select cities
    const cities = provs_cities
      .filter((item) => item.prov === prov)
      .map((i) => i.city)
      .sort((a, b) => (a > b ? 1 : -1));

    setBirthLocs(cities);
  };

  return (
    <>
      {loading && <LinearProgress />}
      {error && !modUser?.isinternal && <Alert severity="error">{error}</Alert>}
      <Grid item xs={3} md={3}>
        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor="birthprov">Provincia di nascita</InputLabel>
          <Select
            id="birthprov"
            label="Provincia di nascita"
            value={modUser.birthprov}
            disabled={locked}
            onChange={(e) => updateCities(e.target.value)}
          >
            <MenuItem key={-1} value="-">
              Seleziona una provincia
            </MenuItem>
            {birthProvs.map((prov, idx) => (
              <MenuItem key={idx} value={prov}>
                {prov}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={9} md={6}>
        <FormControl fullWidth variant="standard">
          <InputLabel htmlFor="birthloc">Luogo di nascita</InputLabel>
          <Select
            id="birthloc"
            label="Luogo di nascita"
            value={modUser.birthloc}
            disabled={locked}
            onChange={(e) => onChangeUserParam("birthloc", e.target.value)}
          >
            <MenuItem key={-1} value="-">
              Seleziona una città
            </MenuItem>
            {birthLocs?.map((city, idx) => (
              <MenuItem key={idx} value={city}>
                {city}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { loadParams, updateDisc } from "../actions/paramActions";
import { Add, Delete, Edit } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { unloadProfile } from "../actions/userActions";
import { baseInfoDialogs } from "../utils/config";
import { uploadBannerImage } from "../utils/docs";

const useStyles = makeStyles({
  deftable: {
    "& td": { fontSize: "0.9rem", paddingTop: 0, paddingBottom: 0 },
  },
});

export default function DisciplinesScreen() {
  const paramList = useSelector((state) => state.paramList);
  const { disciplines, loading, error } = paramList;

  const [editDisc, setEditDisc] = useState(null);
  const [dlgOpen, setDlgOpen] = useState(false);

  const dispatch = useDispatch();

  const classes = useStyles();

  function tableProps() {
    return {
      className: classes.deftable,
    };
  }

  function handleDiscSave(action, disc) {
    dispatch(updateDisc(action, disc));
  }

  function renderDiscActions(value, { rowData }, updateValue) {
    const disc = disciplines.find((d) => d.id === rowData[0]);

    return (
      <Stack direction="row" spacing={0.4}>
        <IconButton
          size="small"
          onClick={() => {
            setEditDisc(disc);
            setDlgOpen(true);
          }}
        >
          <Edit />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => {
            handleDiscSave("remove", disc);
          }}
        >
          <Delete />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => {
            setEditDisc({
              id: "",
              name: "",
              prefix: "",
              email: "",
              banner: "",
              infodlg: [],
            });
            setDlgOpen(true);
          }}
        >
          <Add />
        </IconButton>
      </Stack>
    );
  }

  const discColumns = [
    {
      name: "id",
      label: "Chiave",
    },
    { name: "name", label: "Descrizione" },
    { name: "prefix", label: "Prefisso categorie" },
    { name: "email", label: "e-Mail" },
    {
      name: "actions",
      label: "Azioni",
      options: { customBodyRender: renderDiscActions },
    },
  ];

  useEffect(() => {
    dispatch(unloadProfile());
    if (!disciplines?.length) {
      dispatch(loadParams());
    }
  }, [dispatch, disciplines]);

  const EditDiscDialog = ({ dialogDisc }) => {
    const [disc, setDisc] = useState(dialogDisc);

    const [fileUpload, setFileUpload] = useState({
      progress: 0,
      message: "",
      isError: false,
      currentFile: undefined,
    });

    const handleDlgClose = (save) => {
      if (save) {
        //TODO: check univocita' id e prefix
        handleDiscSave(dialogDisc?.id ? "edit" : "add", disc);
      }

      setDlgOpen(false);
    };

    const uploadBanner = async (event) => {
      const valid = event.currentTarget.form.reportValidity();
      if (!valid) return;

      uploadBannerImage(fileUpload, (event) => {
        setFileUpload({
          ...fileUpload,
          progress: Math.round((100 * event.loaded) / event.total),
        });
      })
        .then((response) => {
          setDisc((oldDisc) => {
            return { ...oldDisc, banner: response.data.filename };
          });

          setFileUpload({
            ...fileUpload,
            progress: 0,
            message: response.data.message,
            isError: false,
          });
        })
        .catch((error) => {
          console.log(error);
          setFileUpload({
            ...fileUpload,
            progress: 0,
            message: "Errore durante caricamento file",
            isError: true,
          });
        });
    };

    return (
      <Dialog open={dlgOpen} onClose={() => handleDlgClose(false)}>
        <DialogTitle>
          {dialogDisc?.id !== "" ? "Modifica " + disc?.name : "Aggiungi"}
        </DialogTitle>
        <DialogContent>
          {!dialogDisc?.id !== "" && (
            <TextField
              margin="dense"
              id="id"
              label="Chiave"
              type="text"
              variant="standard"
              fullWidth
              value={disc?.id}
              disabled={dialogDisc?.id !== ""}
              onChange={(event) => setDisc({ ...disc, id: event.target.value })}
            />
          )}
          <TextField
            margin="dense"
            id="name"
            label="Descrizione"
            type="text"
            variant="standard"
            fullWidth
            value={disc?.name}
            onChange={(event) => setDisc({ ...disc, name: event.target.value })}
          />
          <TextField
            margin="dense"
            id="prefix"
            label="Prefisso"
            type="text"
            variant="standard"
            fullWidth
            value={disc?.prefix}
            disabled={dialogDisc?.id !== ""}
            onChange={(event) =>
              setDisc({ ...disc, prefix: event.target.value })
            }
          />
          <TextField
            margin="dense"
            id="prefix"
            label="e-Mail"
            type="email"
            variant="standard"
            fullWidth
            value={disc?.email}
            onChange={(event) =>
              setDisc({ ...disc, email: event.target.value })
            }
          />
          <InputLabel id="infodlg-select" variant="standard">
            Pulsanti
          </InputLabel>
          <Select
            id="infodlg-select"
            label="Pulsanti"
            multiple
            fullWidth
            value={disc?.infodlg}
            renderValue={() =>
              baseInfoDialogs
                .filter((x) => disc.infodlg.includes(x.key))
                .map((x) => x.title)
                .join(", ")
            }
            onChange={(event) => {
              const infodlg =
                typeof event.target.value === "string"
                  ? event.target.value.split(",")
                  : event.target.value;
              setDisc((oldDisc) => {
                return { ...oldDisc, infodlg };
              });
            }}
          >
            {baseInfoDialogs?.map((bid) => (
              <MenuItem
                key={bid.key}
                value={bid.key}
                sx={{ fontSize: "small" }}
              >
                <Checkbox checked={disc?.infodlg?.includes(bid.key)} />
                <ListItemText primary={bid.title} />
                {/* {bid.title} */}
              </MenuItem>
            ))}
          </Select>
          <Box sx={{ mt: "0.6rem" }}>
            {disc?.banner === undefined ? (
              <Box>Nessun banner di login</Box>
            ) : (
              <Box
                component="img"
                sx={{
                  width: "100%",
                }}
                alt="Banner di login"
                src={
                  process.env.REACT_APP_FILE_URL +
                  "banner/" +
                  "Copertina-DB-Generale-(3000-x-800-px).jpg"
                }
              />
            )}
            {fileUpload.message && (
              <Alert
                variant="standard"
                severity={fileUpload.isError ? "error" : "info"}
              >
                {fileUpload.message}
              </Alert>
            )}
            <Box component="form" autoComplete="off">
              {fileUpload.currentFile && fileUpload.progress > 0 && (
                <Box display="flex" alignItems="center">
                  <Box width="100%" mr={1}>
                    <LinearProgress
                      variant="determinate"
                      value={fileUpload.progress}
                    />
                  </Box>
                  <Box minWidth={35}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                    >{`${fileUpload.progress}%`}</Typography>
                  </Box>
                </Box>
              )}
              <FormControl>
                <label htmlFor="btn-upload">
                  <Input
                    id="btn-upload"
                    type="file"
                    onChange={(event) =>
                      setFileUpload({
                        ...fileUpload,
                        currentFile: event.target.files[0],
                      })
                    }
                    style={{ display: "none" }}
                  />
                  <Button variant="contained" component="span">
                    Scegli file...
                  </Button>
                </label>
              </FormControl>
              <FormControl fullWidth sx={{ margin: "auto" }}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!fileUpload.currentFile}
                  onClick={uploadBanner}
                >
                  Carica
                </Button>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  id="filename"
                  type="text"
                  value={fileUpload.currentFile?.name || "--"}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDlgClose(false)}>Annulla</Button>
          <Button onClick={() => handleDlgClose(true)}>Salva</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {loading && <LinearProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      <>
        <EditDiscDialog dialogDisc={editDisc} />
        {disciplines?.length > 0 && (
          <Box margin={2} flex={true} flexGrow="1">
            <MUIDataTable
              title="Sport"
              data={disciplines}
              columns={discColumns}
              options={{
                selectableRowsHideCheckboxes: true,
                viewColumns: false,
                filter: false,
                rowsPerPage: 15,
                setTableProps: tableProps,
              }}
            />
          </Box>
        )}
      </>
    </>
  );
}

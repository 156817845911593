import { Alert, Box, IconButton, LinearProgress, Stack } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  signout,
  unloadProfile,
  updatedoc,
  updatepayment,
} from "../actions/userActions";
import MUIDataTable from "mui-datatables";
import { loadDocuments, loadWiretransfers } from "../actions/docActions";
import { downloadDoc } from "../utils/docs";
import { loadParams } from "../actions/paramActions";

export default function DocListScreen() {
  const documentList = useSelector((state) => state.documentList);
  const { documents, wiretransfers, loading, error, message } = documentList;
  const [docLoaded, setDocLoaded] = useState(false);
  const [wtLoaded, setWtLoaded] = useState(false);

  const [documentsList, setDocumentsList] = useState([]);
  const [wiretransfersList, setWiretransfersList] = useState([]);

  const [docError, setDocError] = useState(null);

  const paramList = useSelector((state) => state.paramList);
  const { docTypes } = paramList;

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  async function handleDownload(doc, type) {
    const ret = await downloadDoc(
      {
        id: doc.usrId,
        surname: doc.usrSurname,
        name: doc.usrName,
      },
      type,
      doc.id,
      doc.description,
      doc.when2,
      doc.ext
    );

    setDocError(ret?.error);
    if (ret?.error) {
      console.log("cannot download document", ret.error);
      if (ret.exit) dispatch(signout());
    }
  }

  function handleDocumentEdit(usrId, type, docId) {
    if (type === "deletewt")
      dispatch(updatepayment(usrId, type, { wtId: docId }));
    if (type === "deletedoc" || type === "validatedoc")
      dispatch(updatedoc(usrId, type, { docId }));
  }

  function renderUserActions(value, { rowData }, updateValue) {
    const rowIndex =
      params.docType === "generic"
        ? documentsList.findIndex(
            (d) => parseInt(d.id) === parseInt(rowData[0])
          )
        : wiretransfersList.findIndex(
            (w) => parseInt(w.id) === parseInt(rowData[0])
          );

    const user =
      params.docType === "generic"
        ? documentsList[rowIndex]
        : wiretransfersList[rowIndex];

    return (
      <Stack direction="row" spacing={0.4} margin="auto">
        <IconButton
          size="small"
          onClick={() => navigate(`/profile/${user.usrId}`)}
        >
          <EditIcon />
        </IconButton>
        <Box fontSize={"0.8rem"}>{value}</Box>
      </Stack>
    );
  }

  function renderWtActions(value, { rowData }, updateValue) {
    const rowIndex = wiretransfersList.findIndex(
      (w) => parseInt(w.id) === parseInt(rowData[0])
    );

    return (
      <Stack direction="row">
        <IconButton
          onClick={() =>
            handleDocumentEdit(
              wiretransfersList[rowIndex].usrid,
              "deletewt",
              wiretransfersList[rowIndex].id
            )
          }
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          onClick={() =>
            handleDownload(wiretransfersList[rowIndex], "wiretransfers")
          }
        >
          <VisibilityIcon />
        </IconButton>
      </Stack>
    );
  }

  const wiretransferColumns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "fullname",
      label: "Utente",
      options: { customBodyRender: renderUserActions },
    },
    { name: "when", label: "Data" },
    { name: "description", label: "Descrizione" },
    {
      name: "euro",
      label: "Importo",
    },
    { name: "reference", label: "Riferimento" },
    {
      name: "actions",
      label: "Azioni",
      options: { customBodyRender: renderWtActions },
    },
  ];

  function renderDocType(value, { rowData }, updateValue) {
    const rowIndex = documentsList.findIndex(
      (d) => parseInt(d.id) === parseInt(rowData[0])
    );

    if (documentsList[rowIndex].subtype !== "") {
      const st = documentsList[rowIndex].subtype;
      return docTypes.find((dt) => dt.key === st)?.label || "Generico";
    }

    return "Generico";
  }

  function renderDocActions(value, { rowData }, updateValue) {
    const rowIndex = documentsList.findIndex(
      (d) => parseInt(d.id) === parseInt(rowData[0])
    );

    return (
      <Stack direction="row">
        {documentsList[rowIndex].canDelete && (
          <IconButton
            onClick={() =>
              handleDocumentEdit(
                documentsList[rowIndex].usrid,
                "deletedoc",
                documentsList[rowIndex].id
              )
            }
          >
            <DeleteIcon />
          </IconButton>
        )}
        <IconButton
          onClick={() => handleDownload(documentsList[rowIndex], "generic")}
        >
          <VisibilityIcon />
        </IconButton>
        {documentsList[rowIndex].subtype !== "" && (
          <IconButton
            disabled={
              !documentsList[rowIndex].canValidate ||
              documentsList[rowIndex].validated
            }
            onClick={() =>
              handleDocumentEdit(
                documentsList[rowIndex].usrId,
                "validatedoc",
                documentsList[rowIndex].id
              )
            }
          >
            {documentsList[rowIndex].validated ? (
              <CheckCircleIcon />
            ) : (
              <UnpublishedIcon />
            )}
          </IconButton>
        )}
      </Stack>
    );
  }

  const documentColumns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "fullname",
      label: "Utente",
      options: { customBodyRender: renderUserActions },
    },
    { name: "when", label: "Data" },
    { name: "description", label: "Descrizione" },
    {
      name: "type",
      label: "Tipologia",
      options: {
        customBodyRender: renderDocType,
      },
    },
    {
      name: "actions",
      label: "Azioni",
      options: { customBodyRender: renderDocActions },
    },
  ];

  useEffect(() => {
    dispatch(unloadProfile());
    if (!docTypes?.length) {
      dispatch(loadParams());
    }
  }, [dispatch, docTypes]);

  useEffect(() => {
    if (params.docType === "generic" && !docLoaded) {
      dispatch(loadDocuments());
      setDocLoaded(true);
    }
    if (params.docType === "wiretransfer" && !wtLoaded) {
      dispatch(loadWiretransfers());
      setWtLoaded(true);
    }
  }, [params, dispatch, docLoaded, wtLoaded]);

  useEffect(() => {
    if (documents?.length > 0) {
      const newdocs = documents.map((doc) => {
        return {
          ...doc,
          fullname: `${doc.usrSurname} ${doc.usrName}`,
        };
      });

      setDocumentsList(newdocs);
    }
  }, [documents]);

  useEffect(() => {
    if (wiretransfers?.length > 0) {
      const newwts = wiretransfers.map((wt) => {
        return {
          ...wt,
          fullname: `${wt.usrSurname} ${wt.usrName}`,
        };
      });

      setWiretransfersList(newwts);
    }
  }, [wiretransfers]);

  return (
    <>
      {loading && <LinearProgress />}
      {docError && <Alert severity="error">Documento non trovato</Alert>}
      {message && <Alert severity="info">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      <>
        {params.docType === "wiretransfer" && (
          <>
            {wiretransfersList?.length === 0 && !wtLoaded && (
              <Box>Nessun Bonifico</Box>
            )}
            {wiretransfersList?.length > 0 && (
              <Box margin={2} flex={true} flexGrow="1">
                <MUIDataTable
                  title="Bonifici"
                  data={wiretransfersList}
                  columns={wiretransferColumns}
                  options={{
                    selectableRowsHideCheckboxes: true,
                    viewColumns: false,
                    filter: false,
                  }}
                />
              </Box>
            )}
          </>
        )}
        {params.docType === "generic" && (
          <>
            {documentsList?.length === 0 && !docLoaded && (
              <Box>Nessun Documento</Box>
            )}
            {documentsList?.length > 0 && (
              <Box margin={2} flex={true} flexGrow="1">
                <MUIDataTable
                  title="Documenti"
                  data={documentsList}
                  columns={documentColumns}
                  options={{
                    selectableRowsHideCheckboxes: true,
                    viewColumns: false,
                    filter: false,
                  }}
                />
              </Box>
            )}
          </>
        )}
      </>
    </>
  );
}

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCategories,
  loadParams,
  setDiscipline,
  updateGroups,
  updateLocPrefs,
  updateLocations,
} from "../actions/paramActions";
import { loadUsers } from "../actions/usersActions";
import { Add, Delete, Edit } from "@mui/icons-material";
import { unloadProfile } from "../actions/userActions";

export default function LocationsScreen() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const usersList = useSelector((state) => state.usersList);
  const { userProfiles } = usersList;

  const paramList = useSelector((state) => state.paramList);
  const {
    loading,
    error,
    message,
    locations,
    disciplines,
    groupNames,
    locPrefs,
    locPrefsEnabled,
    categories,
  } = paramList;

  const [curDiscipline, setCurDiscipline] = useState("");
  const [location, setLocation] = useState("");
  const [group, setGroup] = useState("");
  const [numUsers, setNumUsers] = useState(0);

  const [locDlgOpen, setLocDlgOpen] = useState(false);
  const [grpDlgOpen, setGrpDlgOpen] = useState(false);
  const [locPrefDlgOpen, setLocPrefDlgOpen] = useState(false);
  const [editLocation, setEditLocation] = useState(undefined);
  const [editGroup, setEditGroup] = useState(undefined);
  const [editLocPref, setEditLocPref] = useState(undefined);

  const dispatch = useDispatch();

  function handleEditLocation(loc) {
    dispatch(updateLocations("edit", loc));
  }

  function handleEditGroup(key, value) {
    dispatch(updateGroups("edit", key, value));
  }

  function handleEditLocPref(lp) {
    dispatch(updateLocPrefs("edit", lp));
  }

  function handleAddLocation(loc) {
    dispatch(updateLocations("add", loc));
  }

  function handleAddGroup(key, value) {
    dispatch(updateGroups("add", key, value));
  }

  function handleAddLocPref(lp) {
    dispatch(updateLocPrefs("add", lp));
  }

  function handleRemoveLocation(loc) {
    dispatch(updateLocations("remove", loc));
  }

  function handleRemoveGroup(key) {
    dispatch(updateGroups("remove", key, undefined));
  }

  function handleRemoveLocPref(lp) {
    dispatch(updateLocPrefs("remove", lp));
  }

  function handleLocPrefsEnabled(value) {
    dispatch(updateLocPrefs(value ? "enable" : "disable", null));
  }

  const changeDiscipline = useCallback(
    (disc) => {
      dispatch(setDiscipline(disc));
      dispatch(loadCategories());
      setCurDiscipline(disc);
      setLocation("");
      setGroup("");
      setNumUsers(0);
    },
    [setCurDiscipline, dispatch]
  );

  const EditLocationDialog = ({ dialogLocation }) => {
    const [loc, setLoc] = useState(dialogLocation);

    const handleDlgClose = (save) => {
      if (save) {
        if (dialogLocation.id) handleEditLocation(loc);
        else handleAddLocation(loc);
      }

      setLocDlgOpen(false);
    };

    return (
      <Dialog open={locDlgOpen} onClose={() => handleDlgClose(false)}>
        <DialogTitle>
          {dialogLocation?.id ? "Modifica " + dialogLocation?.id : "Aggiungi"}
        </DialogTitle>
        <DialogContent>
          {!dialogLocation?.id && (
            <TextField
              margin="dense"
              id="key"
              label="Id"
              type="text"
              variant="standard"
              fullWidth
              value={loc?.id}
              onChange={(event) => setLoc({ ...loc, id: event.target.value })}
            />
          )}
          <TextField
            margin="dense"
            id="description"
            label="Nome"
            type="text"
            variant="standard"
            fullWidth
            value={loc?.name}
            onChange={(event) => setLoc({ ...loc, name: event.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDlgClose(false)}>Annulla</Button>
          <Button onClick={() => handleDlgClose(true)}>Salva</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const EditGroupDialog = ({ dialogGroup }) => {
    const [group, setGroup] = useState(dialogGroup);

    const handleDlgClose = (save) => {
      if (save) {
        if (dialogGroup.key) handleEditGroup(dialogGroup.key, group);
        else handleAddGroup(group.key, group);
      }

      setGrpDlgOpen(false);
    };

    return (
      <Dialog open={grpDlgOpen} onClose={() => handleDlgClose(false)}>
        <DialogTitle>
          {dialogGroup?.key ? "Modifica " + dialogGroup?.key : "Aggiungi"}
        </DialogTitle>
        <DialogContent>
          {!dialogGroup?.key && (
            <TextField
              margin="dense"
              id="key"
              label="Chiave"
              type="text"
              variant="standard"
              fullWidth
              value={group?.key}
              onChange={(event) =>
                setGroup({ ...group, key: event.target.value })
              }
            />
          )}
          <TextField
            margin="dense"
            id="description"
            label="Nome"
            type="text"
            variant="standard"
            fullWidth
            value={group?.name}
            onChange={(event) =>
              setGroup({ ...group, name: event.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDlgClose(false)}>Annulla</Button>
          <Button onClick={() => handleDlgClose(true)}>Salva</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const EditLocPrefDialog = ({ dialogLocPref, location }) => {
    const [locPref, setLocPref] = useState(dialogLocPref);

    const handleDlgClose = (save) => {
      if (save) {
        if (dialogLocPref.id) handleEditLocPref(locPref);
        else handleAddLocPref(locPref);
      }

      setLocPrefDlgOpen(false);
    };

    useEffect(() => {
      if (location && !dialogLocPref?.id && !locPref?.name) {
        setLocPref({ ...locPref, name: "OPENDAY - " + location });
      }
    }, [location, dialogLocPref, locPref]);

    return (
      <Dialog
        open={locPrefDlgOpen}
        group={group}
        onClose={() => handleDlgClose(false)}
      >
        <DialogTitle>
          {dialogLocPref?.id ? "Modifica " + dialogLocPref?.id : "Aggiungi"}
        </DialogTitle>
        <DialogContent>
          {!dialogLocPref?.id && (
            <TextField
              margin="dense"
              id="key"
              label="Chiave"
              type="text"
              variant="standard"
              fullWidth
              required
              value={locPref?.id}
              onChange={(event) =>
                setLocPref({ ...locPref, id: event.target.value })
              }
            />
          )}
          <RadioGroup
            row
            aria-labelledby="type-radio-labels"
            name="row-radio-buttons-group"
            value={locPref?.category || ""}
            onChange={(event) =>
              setLocPref({ ...locPref, category: event.target.value })
            }
          >
            {categories
              ?.filter(
                (cat) =>
                  cat.isinternal === "0" && cat.discipline === curDiscipline
              )
              .map((cat) => (
                <FormControlLabel
                  key={cat.key}
                  value={cat.key}
                  control={<Radio />}
                  label={cat.description}
                />
              ))}
          </RadioGroup>
          <TextField
            margin="dense"
            id="description"
            label="Descrizione"
            type="text"
            variant="standard"
            fullWidth
            value={locPref?.name}
            onChange={(event) =>
              setLocPref({ ...locPref, name: event.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDlgClose(false)}>Annulla</Button>
          <Button
            disabled={!locPref?.id || !locPref.category}
            onClick={() => handleDlgClose(true)}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    dispatch(unloadProfile());
    if (!disciplines?.length) {
      dispatch(loadParams());
    } else if (!curDiscipline && userInfo?.aclDiscipline[0]) {
      changeDiscipline(userInfo?.aclDiscipline[0]);
    } else if (!curDiscipline && disciplines?.length > 0) {
      changeDiscipline(disciplines[0].id);
    }
  }, [dispatch, disciplines, curDiscipline, userInfo, changeDiscipline]);

  useEffect(() => {
    if (!userProfiles?.length) dispatch(loadUsers());
  }, [dispatch, userProfiles]);

  return (
    <>
      <EditLocationDialog dialogLocation={editLocation} />
      <EditGroupDialog dialogGroup={editGroup} />
      <EditLocPrefDialog dialogLocPref={editLocPref} location={location} />
      {loading && <LinearProgress />}
      {message && <Alert severity="info">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      {disciplines &&
        (userInfo?.aclDiscipline?.length > 0 || userInfo?.isAdmin) && (
          <Box padding={1} sx={{ mt: 2 }}>
            <FormControl variant="standard">
              <Select
                id="discipline"
                value={curDiscipline}
                label="Sport"
                onChange={(e) => changeDiscipline(e.target.value)}
              >
                {disciplines
                  ?.filter(
                    (disc) =>
                      userInfo?.aclDiscipline.includes(disc.id) ||
                      userInfo?.isAdmin
                  )
                  .map((disc) => (
                    <MenuItem key={disc.id} value={disc.id}>
                      {disc.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}
      <Box>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            Campi sportivi
          </Grid>
          {locations?.map((loc) => (
            <Grid key={loc.id} item xs={6} md={3}>
              <Stack direction="row">
                <Button
                  fullWidth={true}
                  variant={loc.id === location ? "contained" : "outlined"}
                  onClick={() => {
                    setLocation(loc.id);
                    setGroup("");
                  }}
                >
                  {loc.name}
                </Button>
                <IconButton
                  onClick={() => {
                    setEditLocation(loc);
                    setLocDlgOpen(true);
                  }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleRemoveLocation(loc)}
                  disabled={
                    Object.entries(groupNames)?.filter(
                      ([key, value]) => value.location === loc.id
                    ).length > 0
                  }
                >
                  <Delete />
                </IconButton>
              </Stack>
            </Grid>
          ))}
          <Grid item xs={3}>
            <IconButton
              size="small"
              onClick={() => {
                setEditLocation({
                  id: "",
                  name: "",
                });
                setLocDlgOpen(true);
              }}
            >
              <Add />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            Gruppi
          </Grid>
          {groupNames &&
            Object.entries(groupNames)
              ?.filter(([key, value]) => value.location === location)
              .map(([key, value]) => (
                <Grid key={key} item xs={6} md={3}>
                  <Stack direction="row">
                    <Button
                      fullWidth={true}
                      variant={group === key ? "contained" : "outlined"}
                      onClick={() => {
                        setGroup(key);
                        setNumUsers(
                          userProfiles?.filter((user) => user.group === key)
                            .length
                        );
                      }}
                    >
                      {value.name}
                    </Button>
                    <IconButton
                      onClick={() => {
                        setEditGroup({ key: key, ...value });
                        setGrpDlgOpen(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveGroup(key)}
                      disabled={
                        userProfiles?.filter((user) => user.group === key)
                          .length > 0
                      }
                    >
                      <Delete />
                    </IconButton>
                  </Stack>
                </Grid>
              ))}
          <Grid item xs={3}>
            <IconButton
              size="small"
              disabled={location === ""}
              onClick={() => {
                setEditGroup({
                  key: "",
                  name: "",
                  location: location,
                });
                setGrpDlgOpen(true);
              }}
            >
              <Add />
            </IconButton>
          </Grid>
          {group && (
            <Grid item xs={12}>
              Utenti del gruppo: {numUsers}
            </Grid>
          )}
          {/* TODO: pulsante per spostare? */}
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={8}>
            Open Days
          </Grid>
          <Grid item xs={4}>
            <FormLabel>Abilitazione</FormLabel>
            <Switch
              disabled={locPrefs?.length === 0}
              checked={locPrefsEnabled}
              onChange={(event) => handleLocPrefsEnabled(event.target.checked)}
            />
          </Grid>
          {locPrefs?.map((lp) => (
            <Grid key={lp.id} item xs={6} md={3}>
              <Stack direction="row">
                <Button
                  fullWidth={true}
                  color={!lp?.category ? "warning" : "primary"}
                >
                  {lp.name}
                  {lp?.category &&
                    ` [${
                      categories?.find((cat) => cat.key === lp?.category)
                        ?.description
                    }]`}
                </Button>
                <IconButton
                  onClick={() => {
                    setEditLocPref(lp);
                    setLocPrefDlgOpen(true);
                  }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleRemoveLocPref(lp)}
                >
                  <Delete />
                </IconButton>
              </Stack>
            </Grid>
          ))}
          <Grid item xs={3}>
            <IconButton
              size="small"
              disabled={!location}
              onClick={() => {
                setEditLocPref({
                  id: "",
                  name: "",
                });
                setLocPrefDlgOpen(true);
              }}
            >
              <Add />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

import axiosInstance, { manageReason } from "./axios";

import {
  CATASTO_LOAD_FAIL,
  CATASTO_LOAD_REQUEST,
  CATASTO_LOAD_SUCCESS,
} from "../constants/catastoConstants";

import { signout } from "./userActions";

export const loadCatasto = () => async (dispatch) => {
  dispatch({ type: CATASTO_LOAD_REQUEST, payload: {} });
  axiosInstance
    .get("/users/getcatasto.php")
    .then((response) => {
      if (response === undefined) {
        dispatch(signout());
      } else {
        dispatch({
          type: CATASTO_LOAD_SUCCESS,
          payload: response.data,
        });
      }
    })
    .catch((reason) => {
      const { message, exit } = manageReason(reason);
      if (exit) {
        dispatch(signout());
      } else {
        dispatch({
          type: CATASTO_LOAD_FAIL,
          payload: message,
        });
      }
    });
};

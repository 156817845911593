import {
  CATASTO_LOAD_FAIL,
  CATASTO_LOAD_REQUEST,
  CATASTO_LOAD_SUCCESS,
} from "../constants/catastoConstants";

export const catastoReducer = (state = { provs_cities: [] }, action) => {
  switch (action.type) {
    case CATASTO_LOAD_REQUEST:
      return { ...state, loading: true };
    case CATASTO_LOAD_SUCCESS:
      return { loading: false, provs_cities: action.payload };
    case CATASTO_LOAD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signout } from "../actions/userActions";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  appVersion,
  baseFileUrl,
  canManageAttendees,
  canRegisterTrial,
  canTransfer,
  emailSupport,
  //  transferDocs,
} from "../utils/config";
import { downloadDBBackup } from "../utils/docs";
import { AdminPanelSettings, Tune } from "@mui/icons-material";
import { isParentOf } from "../utils/user";
import { loadParams } from "../actions/paramActions";
import { refresh } from "../actions/axios";

export default function NavigationBar() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const userDetails = useSelector((state) => state.userDetails);
  const { userProfile } = userDetails;

  const paramList = useSelector((state) => state.paramList);
  const { docTypes } = paramList;

  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const [anchorElParent, setAnchorElParent] = useState(null);
  const [anchorElManager, setAnchorElManager] = useState(null);
  const [anchorElDisc, setAnchorElDisc] = useState(null);
  const [anchorElAdmin, setAnchorElAdmin] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const [manageProfile, setManageProfile] = useState([]);
  const [manageParent, setManageParent] = useState([]);
  const [manageManager, setManageManager] = useState([]);
  const [manageDisc, setManageDisc] = useState([]);
  const [manageAdmin, setManageAdmin] = useState([]);
  const [manageUser, setManageUser] = useState([]);

  const [aboutBoxOpen, setAboutBoxOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpenProfileMenu = (event) => {
    setAnchorElProfile(event.currentTarget);
  };
  const handleOpenParentMenu = (event) => {
    setAnchorElParent(event.currentTarget);
  };
  const handleOpenManagerMenu = (event) => {
    setAnchorElManager(event.currentTarget);
  };
  const handleOpenDiscMenu = (event) => {
    setAnchorElDisc(event.currentTarget);
  };
  const handleOpenAdminMenu = (event) => {
    setAnchorElAdmin(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setAnchorElProfile(null);
  };
  const handleCloseParentMenu = () => {
    setAnchorElParent(null);
  };
  const handleCloseManagerMenu = () => {
    setAnchorElManager(null);
  };
  const handleCloseDiscMenu = () => {
    setAnchorElDisc(null);
  };
  const handleCloseAdminMenu = () => {
    setAnchorElAdmin(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSelectProfileMenu = (item) => {
    if (item.to) navigate(item.to);
    else item.do(item.param);

    setAnchorElProfile(null);
  };
  const handleSelectParentMenu = (item) => {
    if (item.to) navigate(item.to);
    else item.do(item.param);

    setAnchorElParent(null);
  };
  const handleSelectManagerMenu = (item) => {
    if (item.to) navigate(item.to);
    else item.do(item.param);

    setAnchorElManager(null);
  };
  const handleSelectDiscMenu = (item) => {
    if (item.to) navigate(item.to);
    else item.do(item.param);

    setAnchorElDisc(null);
  };
  const handleSelectAdminMenu = (item) => {
    if (item.to) navigate(item.to);
    else item.do(item.param);

    setAnchorElAdmin(null);
  };
  const handleSelectUserMenu = (item) => {
    if (item.mail) window.location = `mailto:${item.mail}`;
    else if (item.to) navigate(item.to);
    else item.do();

    setAnchorElUser(null);
  };

  const downloadBackup = useCallback(async () => {
    const ret = await downloadDBBackup();
    if (ret?.error) {
      console.log("cannot download backup", ret.error);
      if (ret.exit) dispatch(signout());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!docTypes?.length) {
      dispatch(loadParams());
    }
  }, [dispatch, docTypes]);

  useEffect(() => {
    if (userProfile?.id) {
      let menu = [];
      if (!userProfile.isinternal) {
        menu = [
          {
            menu: "Modulo Iscrizione",
            to: `/profile/${userProfile.id}/signup`,
            visible: process.env.REACT_APP_EMBEDDEDSIGUPMODULE === true,
          },
          {
            menu: "Scarica e compila",
            to: "/modules",
            visible: docTypes?.length > 0,
          },
          {
            menu: "Carica/visualizza documenti",
            to: `/profile/${userProfile.id}/docs`,
            visible: true,
          },
          {
            menu: "Profilo",
            to: `/profile/${userProfile.id}`,
            visible:
              userInfo &&
              (userInfo.hasAcl || isParentOf(userInfo, userProfile)),
          },
          {
            menu: "Dati Iscrizione",
            to: `/profile/${userProfile.id}/payments`,
            visible: userInfo && userInfo.hasAcl,
          },
        ];
      }

      setManageProfile(menu);

      if (userInfo?.isParent) {
        const menuchildren = userInfo?.children.map((child) => {
          return {
            menu: `${child.name}`,
            to: `/profile/${child.id}`,
            visible: true,
          };
        });

        let menuparent = [
          ...menuchildren,
          {
            menu: "Prenota prova per nuovo iscritto",
            to: `/trial/all?add=true`,
            visible: canRegisterTrial,
          },
          {
            menu: "Aggiungi nuovo iscritto",
            to: `/signup/all?add=true`,
            visible: true,
          },
        ];

        setManageParent(menuparent);
      } else {
        setManageParent([]);
      }
    } else {
      setManageProfile([]);
      setManageParent([]);
    }
  }, [userProfile, userInfo, docTypes]);

  const logoutHandler = useCallback(() => {
    dispatch(signout());
  }, [dispatch]);

  const refreshHandler = () => {
    refresh();
  };

  const openAbouBox = () => {
    setAboutBoxOpen(true);
  };

  const AboutBoxDialog = () => {
    return (
      <Dialog open={aboutBoxOpen} onClose={() => setAboutBoxOpen(false)}>
        <DialogTitle>Informazioni applicazione</DialogTitle>
        <DialogContent>
          <Box>Versione: {appVersion}</Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAboutBoxOpen(false)}>Chiudi</Button>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    if (userInfo?.id) {
      let menu = [
        {
          menu: "Mio Profilo",
          to: userInfo?.isParent
            ? `/profile/${userInfo?.childid}/parents/${userInfo?.id}`
            : `/profile/${userInfo?.id}`,
          visible: true,
        },
        {
          menu: "Refresh Token",
          do: refreshHandler,
          visible: userInfo?.isAdmin,
        },
        {
          menu: "Richiedi assistenza",
          mail: emailSupport,
          visible: emailSupport !== undefined,
        },
        { menu: "Disconnetti", do: logoutHandler, visible: true },
        {
          menu: "Informazioni",
          do: openAbouBox,
          visible: userInfo?.isAdmin,
        },
      ];

      setManageUser(menu);
    } else {
      setManageUser([]);
    }

    if (userInfo?.hasAcl || userInfo?.isAdmin) {
      let menu = [
        { menu: "Lista Utenti", to: "/users", visible: true },
        {
          menu: "Fogli Presenze",
          to: "/attendees",
          visible: userInfo?.hasAcl && canManageAttendees,
        },
        { menu: "Lista Documenti", to: "/documents/generic", visible: true },
        {
          menu: "Lista Bonifici",
          to: "/documents/wiretransfer",
          visible: userInfo.isManager,
        },
        {
          menu: "Nuovo Utente",
          to: "/signup/all",
          visible: true,
        },
        {
          menu: "Scadenze Certificati",
          to: "/useralerts",
          visible: true,
        },
        {
          menu: "Importa Utenti",
          to: "/transferusers",
          visible: canTransfer && userInfo?.hasAcl,
        },
      ];

      setManageManager(menu);
    } else {
      setManageManager([]);
    }

    if (userInfo?.mgrDiscipline?.length > 0 || userInfo?.isAdmin) {
      let discmenu = [
        {
          menu: "Configura Tipologie Utenti",
          to: "/categories",
          visible: true,
        },
        {
          menu: "Imposta Campi sportivi e gruppi",
          to: "/locations",
          visible: true,
        },
        {
          menu: "Configura Modulistica",
          to: "/modulesedit",
          visible: true,
        },
        {
          menu: "Riepilogo Presenze",
          to: "/attendeessummary",
          visible: userInfo?.hasAcl && canManageAttendees,
        },
      ];

      setManageDisc(discmenu);
    } else {
      setManageDisc([]);
    }

    if (userInfo?.isAdmin) {
      let admmenu = [
        {
          menu: "Backup Database",
          do: downloadBackup,
          visible: true,
        },
        {
          menu: "Cleanup Database",
          to: "/dbcleanup",
          visible: true,
        },
        {
          menu: "Configura Sport",
          to: "/disciplines",
          visible: true,
        },
        {
          menu: "Configura Tariffe",
          to: "/pricelist",
          visible: true,
        },
        // {
        //   menu: "Copia documenti Pontevecchio",
        //   do: performAction,
        //   param: "copyPVdocs",
        //   visible: transferDocs,
        // },
        // {
        //   menu: "Sposta documenti Pontevecchio",
        //   do: performAction,
        //   param: "movePVdocs",
        //   visible: transferDocs,
        // },
        {
          menu: "Imposta Parametri",
          to: "/parameters",
          visible: true,
        },
        {
          menu: "Visualizza Log Azioni",
          to: "/logs",
          visible: true,
        },
        {
          menu: "Visualizza Sessioni",
          to: "/sessions",
          visible: true,
        },
      ];

      setManageAdmin(admmenu);
    } else {
      setManageAdmin([]);
    }
  }, [userInfo, dispatch, navigate, logoutHandler, downloadBackup]);

  return (
    <AppBar
      position="sticky"
      sx={{ bgcolor: process.env.REACT_APP_APPBARCOLOR || "primary" }}
    >
      <AboutBoxDialog />
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Button
            onClick={() =>
              (window.location.href = process.env.REACT_APP_MAINSITE_URL)
            }
            sx={{
              m: 1,
              p: 1,
              display: { xs: "none", md: "flex" },
              background: "white",
            }}
          >
            <img
              src={
                process.env.PUBLIC_URL + "/" + process.env.REACT_APP_LOGO_URL
              }
              alt="logo"
              height={60}
            />
          </Button>
          {userInfo?.id === undefined && (
            <>
              {userProfile?.isinternal ? (
                <Box sx={{ flexGrow: 0, display: "flex", ml: 6 }} />
              ) : (
                <>
                  {canRegisterTrial && (
                    <Box sx={{ flexGrow: 0, display: "flex", ml: 6 }}>
                      <Button
                        color="inherit"
                        onClick={() => navigate("/trial/all")}
                      >
                        <Typography>Prenota la prova</Typography>
                      </Button>
                    </Box>
                  )}
                  <Box sx={{ flexGrow: 0, display: "flex", ml: 6 }}>
                    <Button
                      color="inherit"
                      onClick={() => navigate("/signup/all")}
                    >
                      <Typography>Registrati</Typography>
                    </Button>
                  </Box>
                  <Box sx={{ flexGrow: 1, display: "flex", ml: 2 }}>
                    <Button color="inherit" onClick={() => navigate("/")}>
                      <Typography>Accedi</Typography>
                    </Button>
                  </Box>
                </>
              )}
            </>
          )}
          {manageProfile.length > 0 && (
            <Box
              sx={{
                ml: 6,
                flexGrow:
                  manageManager.length > 0 || manageParent.length > 0 ? 0 : 1,
                display: "flex",
              }}
            >
              <Tooltip title="Gestione profilo utente">
                <Button
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenProfileMenu}
                  color="inherit"
                  sx={{ fontSize: "0.8rem" }}
                  startIcon={<ManageAccountsIcon />}
                >
                  {userProfile?.name} {userProfile?.surname}
                </Button>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElProfile}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElProfile)}
                onClose={handleCloseProfileMenu}
                sx={{
                  display: "block",
                }}
              >
                {manageProfile.map((item) => (
                  <MenuItem
                    key={item.menu}
                    onClick={() => handleSelectProfileMenu(item)}
                    sx={{ display: item.visible ? "block" : "none" }}
                  >
                    <Typography textAlign="center">{item.menu}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}

          {manageParent.length > 0 && (
            <Box
              sx={{
                ml: 6,
                flexGrow: manageManager.length > 0 ? 0 : 1,
                display: "flex",
              }}
            >
              <Tooltip title="Gestione famiglia">
                <Button
                  aria-label="account of current parent"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenParentMenu}
                  color="inherit"
                  sx={{ fontSize: "0.8rem" }}
                  startIcon={<ManageAccountsIcon />}
                >
                  Famiglia
                </Button>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElParent}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElParent)}
                onClose={handleCloseParentMenu}
                sx={{
                  display: "block",
                }}
              >
                {manageParent.map((item) => (
                  <MenuItem
                    key={item.menu}
                    onClick={() => handleSelectParentMenu(item)}
                    sx={{ display: item.visible ? "block" : "none" }}
                  >
                    <Typography textAlign="center">{item.menu}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}

          {manageManager.length > 0 && (
            <Box
              sx={{ flexGrow: manageDisc.length > 0 ? 0 : 1, display: "flex" }}
            >
              <Tooltip title="Gestione atleti">
                <Button
                  aria-label="administration"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenManagerMenu}
                  color="inherit"
                  sx={{ fontSize: "0.8rem" }}
                  startIcon={<SettingsIcon />}
                >
                  Iscritti
                </Button>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElManager}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElManager)}
                onClose={handleCloseManagerMenu}
                sx={{
                  display: "block",
                }}
              >
                {manageManager.map((item) => (
                  <MenuItem
                    key={item.menu}
                    onClick={() => handleSelectManagerMenu(item)}
                    sx={{ display: item.visible ? "block" : "none" }}
                  >
                    <Typography textAlign="center">{item.menu}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}

          {manageDisc.length > 0 && (
            <Box
              sx={{ flexGrow: manageAdmin.length > 0 ? 0 : 1, display: "flex" }}
            >
              <Tooltip title="Menu Dirigenti Sportivi">
                <Button
                  aria-label="administration3"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenDiscMenu}
                  color="inherit"
                  sx={{ fontSize: "0.8rem" }}
                  startIcon={<Tune />}
                >
                  Dirigente
                </Button>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElDisc}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElDisc)}
                onClose={handleCloseDiscMenu}
                sx={{
                  display: "block",
                }}
              >
                {manageDisc.map((item) => (
                  <MenuItem
                    key={item.menu}
                    onClick={() => handleSelectDiscMenu(item)}
                    sx={{ display: item.visible ? "block" : "none" }}
                  >
                    <Typography textAlign="center">{item.menu}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}

          {manageAdmin.length > 0 && (
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <Tooltip title="Impostazioni Gestionale">
                <Button
                  aria-label="administration2"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenAdminMenu}
                  color="inherit"
                  sx={{ fontSize: "0.8rem" }}
                  startIcon={<AdminPanelSettings />}
                >
                  Amministrazione
                </Button>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElAdmin}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElAdmin)}
                onClose={handleCloseAdminMenu}
                sx={{
                  display: "block",
                }}
              >
                {manageAdmin.map((item) => (
                  <MenuItem
                    key={item.menu}
                    onClick={() => handleSelectAdminMenu(item)}
                    sx={{ display: item.visible ? "block" : "none" }}
                  >
                    <Typography textAlign="center">{item.menu}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}

          {manageUser.length > 0 && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Mio profilo">
                <Button
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                  endIcon={
                    userInfo?.photourl ? (
                      <Avatar
                        alt={userInfo?.name}
                        src={baseFileUrl + userInfo?.photourl}
                      />
                    ) : (
                      <PersonIcon />
                    )
                  }
                  color="inherit"
                >
                  {userInfo?.name}
                </Button>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {manageUser.map((item) => (
                  <MenuItem
                    key={item.menu}
                    onClick={() => handleSelectUserMenu(item)}
                    sx={{ display: item.visible ? "block" : "none" }}
                  >
                    <Typography textAlign="center">{item.menu}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

import {
  Alert,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  loadProfile,
  signout,
  updatedoc,
  updatepayment,
} from "../actions/userActions";
import MUIDataTable from "mui-datatables";
import { downloadDoc, uploadDoc } from "../utils/docs";
import { loadCategories, loadParams } from "../actions/paramActions";
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { findDiscByCategory } from "../utils/utils";

export default function DocumentScreen() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const userDetails = useSelector((state) => state.userDetails);
  const { userProfile, loading, error, message } = userDetails;

  const paramList = useSelector((state) => state.paramList);
  const { categories, disciplines, docTypes } = paramList;

  const [docType, setDoctype] = useState(null);
  const [docError, setDocError] = useState(null);

  const [filtereddocs, setFiltereddocs] = useState([]);

  const [fileUpload, setFileUpload] = useState({
    docType: "generic",
    subDocType: "",
    toBeSigned: false,
    docDateD: new Date(),
    docDescription: "",
    docRef: "",
    docEuro: "0",
    isOpen:
      userProfile?.documents === undefined ||
      userProfile.documents.length === 0,
    progress: 0,
    message: "",
    isError: false,
    currentFile: undefined,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  function selectFile(event) {
    setFileUpload({ ...fileUpload, currentFile: event.target.files[0] });
  }

  const uploadFile = async (event, gotoprofile) => {
    const valid = event.currentTarget.form.reportValidity();
    if (!valid) return;

    const docType = fileUpload.docType.startsWith("sd_")
      ? "generic"
      : fileUpload.docType;

    uploadDoc(
      userProfile.id,
      findDiscByCategory(userProfile.category, categories, disciplines),
      {
        ...fileUpload,
        docType,
        docDate: moment(fileUpload.docDateD).format("DD/MM/YYYY"),
      },
      dispatch,
      (event) => {
        setFileUpload({
          ...fileUpload,
          progress: Math.round((100 * event.loaded) / event.total),
        });
      }
    )
      .then((response) => {
        setFileUpload({
          ...fileUpload,
          progress: 0,
          message: response.data.message,
          isError: false,
        });

        if (gotoprofile) {
          navigate(`/profile/${userProfile.id}`);
        } else {
          // reload
          // TODO: dal servizio upload generare evento
          dispatch(loadProfile(userProfile.id));
        }
      })
      .catch((error) => {
        console.log(error);
        setFileUpload({
          ...fileUpload,
          progress: 0,
          message: "Errore durante caricamento file",
          isError: true,
        });
      });
    //setFileUpload({ ...fileUpload, currentFile: undefined });
  };

  async function handleDownload(profile, type, doc) {
    const ret = await downloadDoc(
      profile,
      type,
      doc.id,
      doc.description,
      doc.when2,
      doc.ext
    );

    setDocError(ret?.error);
    if (ret?.error) {
      console.log("cannot download document", ret.error);
      if (ret.exit) dispatch(signout());
    }
  }

  function handleDocumentEdit(type, docId) {
    if (type === "deletewt")
      dispatch(updatepayment(userProfile.id, type, { wtId: docId }));
    if (type === "deletedoc" || type === "validatedoc")
      dispatch(updatedoc(userProfile.id, type, { docId }));
  }

  function renderWtActions(value, { rowIndex, columnIndex }, updateValue) {
    return (
      <Stack direction="row">
        <IconButton
          onClick={() =>
            handleDocumentEdit(
              "deletewt",
              userProfile.wiretransfers[rowIndex].id
            )
          }
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          onClick={() =>
            handleDownload(
              userProfile,
              "wiretransfers",
              userProfile.wiretransfers[rowIndex]
            )
          }
        >
          <VisibilityIcon />
        </IconButton>
      </Stack>
    );
  }

  const wiretransferColumns = [
    { name: "when", label: "Data" },
    { name: "description", label: "Descrizione" },
    {
      name: "euro",
      label: "Importo",
    },
    { name: "reference", label: "Riferimento" },
    {
      name: "actions",
      label: "Azioni",
      options: { customBodyRender: renderWtActions },
    },
  ];

  function renderDocType(value, { rowIndex, columnIndex }, updateValue) {
    if (filtereddocs[rowIndex].subtype !== "") {
      const st = filtereddocs[rowIndex].subtype;
      return docTypes.find((dt) => dt.key === st)?.label || st;
    }

    if (filtereddocs[rowIndex].tobesigned) return "Da firmare";
    if (filtereddocs[rowIndex].cert) return "Certificato Medico";

    return "Generico";
  }

  function renderDocActions(value, { rowIndex, columnIndex }, updateValue) {
    const doc = filtereddocs[rowIndex];

    return (
      <Stack direction="row">
        {doc.canDelete && (
          <IconButton onClick={() => handleDocumentEdit("deletedoc", doc.id)}>
            <DeleteIcon />
          </IconButton>
        )}

        <IconButton
          onClick={() =>
            handleDownload(userProfile, doc.cert ? "compcert" : "generic", doc)
          }
        >
          <VisibilityIcon />
        </IconButton>
        {doc.subtype !== "" && (
          <IconButton
            disabled={!doc.canValidate || doc.validated}
            onClick={() => handleDocumentEdit("validatedoc", doc.id)}
          >
            {doc.validated ? <CheckCircleIcon /> : <UnpublishedIcon />}
          </IconButton>
        )}
      </Stack>
    );
  }

  const documentColumns = [
    { name: "when", label: "Data" },
    { name: "description", label: "Descrizione" },
    {
      name: "type",
      label: "Tipologia",
      options: {
        customBodyRender: renderDocType,
      },
    },
    {
      name: "actions",
      label: "Azioni",
      options: { customBodyRender: renderDocActions },
    },
  ];

  useEffect(() => {
    if (userProfile?.id !== params.id) {
      dispatch(loadProfile(params.id));
    }

    if (params?.type !== undefined) {
      setDoctype(params.type);
    }
  }, [params, userProfile, dispatch]);

  useEffect(() => {
    if (!categories?.length) {
      dispatch(loadCategories());
    }
  }, [dispatch, categories]);

  useEffect(() => {
    if (!disciplines?.length) {
      dispatch(loadParams());
    }
  }, [dispatch, disciplines]);

  useEffect(() => {
    let filtdocs = [];
    if (
      userProfile?.certfilename !== undefined &&
      userProfile.certfilename !== ""
    ) {
      filtdocs.push({
        id: 1,
        cert: true,
        description: "Certificato Medico",
        when: `(${userProfile.certexpire})`,
        when2: "",
        ext: userProfile.certfilename.split(".").pop(),
        type: "compcert",
        subtype: "",
        tobesigned: false,
        canDelete: false,
        canValidate: false,
      });
    }
    if (userProfile?.documents !== undefined) {
      filtdocs = [
        ...filtdocs,
        ...userProfile?.documents.filter(
          (doc) => !doc.tobesigned || userInfo?.isManager || userInfo?.isAdmin
        ),
      ];
    }

    setFiltereddocs(filtdocs);
  }, [userProfile, userInfo]);

  return (
    <>
      {loading && <LinearProgress />}
      {docError && <Alert severity="error">Documento non trovato</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      {message && <Alert severity="info">{message}</Alert>}
      {userProfile?.certerr && (
        <Alert severity="error">{userProfile.certerr}</Alert>
      )}
      <>
        <FormLabel id="enable">Carica documento</FormLabel>
        <Switch
          checked={fileUpload.isOpen}
          sx={{ mt: 1 }}
          onChange={(event) =>
            setFileUpload({ ...fileUpload, isOpen: event.target.checked })
          }
        />
        {fileUpload.isOpen && (
          <>
            {fileUpload.message && (
              <Alert
                variant="standard"
                severity={fileUpload.isError ? "error" : "info"}
              >
                {fileUpload.message}
              </Alert>
            )}
            <Box component="form" autoComplete="off">
              <Grid container spacing={2} padding={2}>
                {fileUpload.currentFile && fileUpload.progress > 0 && (
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <Box width="100%" mr={1}>
                        <LinearProgress
                          variant="determinate"
                          value={fileUpload.progress}
                        />
                      </Box>
                      <Box minWidth={35}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        >{`${fileUpload.progress}%`}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={8} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="type" variant="standard">
                      Tipo documento
                    </InputLabel>
                    <Select
                      id="type"
                      label="Tipo Documento"
                      value={fileUpload.docType}
                      onChange={(event) => {
                        const v = event.target.value;
                        let docDescription = fileUpload.docDescription;
                        let subDocType = "";

                        if (v.startsWith("sd_")) {
                          const idx = parseInt(
                            event.target.value.replace("sd_", "")
                          );
                          docDescription = docTypes[idx].label;
                          subDocType = docTypes[idx].key;
                        }

                        setFileUpload({
                          ...fileUpload,
                          docType: v,
                          subDocType,
                          docDescription,
                        });
                      }}
                    >
                      {docTypes?.map(
                        (dt, index) =>
                          (userInfo?.isManager ||
                            userInfo?.isAdmin ||
                            dt?.public) && (
                            <MenuItem key={index} value={`sd_${index}`}>
                              {dt.label}
                            </MenuItem>
                          )
                      )}
                      <MenuItem value="generic">Generico</MenuItem>
                      {(userProfile?.iscomp || userProfile?.isjr) && (
                        <MenuItem value="compcert">Certificato Medico</MenuItem>
                      )}
                      <MenuItem value="photo">Fotografia</MenuItem>
                      <MenuItem value="wiretransfer">Bonifico</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {(userInfo?.isManager || userInfo?.isAdmin) && (
                  <Grid item xs={2}>
                    <FormLabel>Da firmare</FormLabel>
                    <Switch
                      checked={fileUpload.toBeSigned}
                      onChange={(event) =>
                        setFileUpload({
                          ...fileUpload,
                          toBeSigned: event.target.checked,
                        })
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={2} md={2}>
                  <FormControl fullWidth>
                    <label htmlFor="btn-upload">
                      <Input
                        id="btn-upload"
                        type="file"
                        onChange={selectFile}
                        style={{ display: "none" }}
                      />
                      <Button variant="contained" component="span">
                        Scegli file...
                      </Button>
                    </label>
                  </FormControl>
                </Grid>
                <Grid item xs={2} md={2}>
                  <FormControl fullWidth sx={{ margin: "auto" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={!fileUpload.currentFile}
                      onClick={(event) => uploadFile(event, false)}
                    >
                      Carica
                    </Button>
                  </FormControl>
                </Grid>
                <Grid item xs={2} md={2}>
                  <FormControl fullWidth sx={{ margin: "auto" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={!fileUpload.currentFile}
                      onClick={(event) => uploadFile(event, true)}
                    >
                      Carica e ritorna al profilo
                    </Button>
                  </FormControl>
                </Grid>

                <Grid item xs={6} md={4}>
                  <FormControl fullWidth>
                    <TextField
                      id="docdesc"
                      label="Descrizione"
                      type="text"
                      value={fileUpload.docDescription}
                      onChange={(event) =>
                        setFileUpload({
                          ...fileUpload,
                          docDescription: event.target.value,
                        })
                      }
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4} md={2}>
                  <FormControl fullWidth>
                    <MobileDatePicker
                      inputFormat="DD/MM/YYYY"
                      id="docdate"
                      label={
                        fileUpload.docType === "compcert"
                          ? "Data Scadenza Certificato Medico"
                          : "Data validità"
                      }
                      type="text"
                      value={fileUpload.docDateD}
                      onChange={(value) =>
                        setFileUpload({
                          ...fileUpload,
                          docDateD: value,
                        })
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={10} md={6}>
                  <FormControl fullWidth>
                    <TextField
                      id="filename"
                      type="text"
                      value={fileUpload.currentFile?.name || "--"}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </Grid>

                {fileUpload.docType === "wiretransfer" && (
                  <>
                    <Grid item xs={3} md={3}>
                      <FormControl fullWidth>
                        <TextField
                          id="doceuro"
                          label="Importo"
                          type="text"
                          value={fileUpload.docEuro}
                          onChange={(event) =>
                            setFileUpload({
                              ...fileUpload,
                              docEuro: event.target.value,
                            })
                          }
                          required={fileUpload.docType === "wiretransfer"}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          id="docref"
                          label="Riferimento"
                          type="text"
                          value={fileUpload.docRef}
                          onChange={(event) =>
                            setFileUpload({
                              ...fileUpload,
                              docRef: event.target.value,
                            })
                          }
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </>
        )}

        {(userProfile?.iscomp || userProfile?.isJr) &&
          userProfile?.certfilename === "" && (
            <Box>Nessun Certificato Medico</Box>
          )}

        {(docType === null || docType === "wiretransfers") && (
          <>
            {(userProfile?.wiretransfers === undefined ||
              userProfile.wiretransfers.length === 0) && (
              <Box>Nessun Bonifico</Box>
            )}
            {userProfile?.wiretransfers &&
              userProfile.wiretransfers.length > 0 && (
                <Box margin={2} flex={true} flexGrow="1">
                  <MUIDataTable
                    title="Bonifici"
                    data={userProfile?.wiretransfers}
                    columns={wiretransferColumns}
                    options={{
                      selectableRowsHideCheckboxes: true,
                      pagination: false,
                      download: false,
                      print: false,
                      viewColumns: false,
                      filter: false,
                      search: false,
                      sort: false,
                    }}
                  />
                </Box>
              )}
          </>
        )}
        {(docType === null || docType === "generic") && (
          <>
            {filtereddocs.length === 0 && <Box>Nessun Documento</Box>}
            {filtereddocs.length > 0 && (
              <Box margin={2} flex={true} flexGrow="1">
                <MUIDataTable
                  title="Documenti"
                  data={filtereddocs}
                  columns={documentColumns}
                  options={{
                    selectableRowsHideCheckboxes: true,
                    download: false,
                    print: false,
                    viewColumns: false,
                    filter: false,
                    search: false,
                    sort: false,
                  }}
                />
              </Box>
            )}
          </>
        )}
      </>
    </>
  );
}

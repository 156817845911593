import { Alert, Box } from "@mui/material";
import { loadParams } from "../actions/paramActions";
import { Link } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { baseFileUrl } from "../utils/config";
import { downloadDoc } from "../utils/docs";
import { signout } from "../actions/userActions";
import { useNavigate } from "react-router-dom";

export default function DocModulesScreen() {
  const userDetails = useSelector((state) => state.userDetails);
  const { userProfile } = userDetails;
  const paramList = useSelector((state) => state.paramList);
  const { docTypes } = paramList;

  const [docError, setDocError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!docTypes?.length) {
      dispatch(loadParams());
    }
  }, [dispatch, docTypes]);

  async function handleDownload(profile, doc) {
    const ret = await downloadDoc(
      profile,
      "generic",
      doc.id,
      doc.description,
      doc.when2,
      doc.ext
    );

    setDocError(ret?.error);
    if (ret?.error) {
      console.log("cannot download document", ret.error);
      if (ret.exit) dispatch(signout());
    }
  }

  return (
    <>
      {docError && <Alert severity="error">Documento non trovato</Alert>}
      <Box
        display="flex"
        marginTop={2}
        flexDirection="column"
        alignItems="center"
      >
        {docTypes?.length > 0 &&
          userProfile &&
          docTypes
            .filter(
              (m) => m?.profileurl !== undefined || m?.filename !== undefined
            )
            .map((m) =>
              m.profileurl !== undefined ? (
                <Link
                  marginBottom={1}
                  key={m.key}
                  target="_blank"
                  rel="noreferrer"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/profile/${userProfile.id}${m.profileurl}`)
                  }
                >
                  Scarica {m.label}
                </Link>
              ) : (
                <Link
                  marginBottom={1}
                  key={m.key}
                  target="_blank"
                  rel="noreferrer"
                  href={`${baseFileUrl}/${m.filename}`}
                >
                  Scarica {m.label}
                </Link>
              )
            )}
        {userProfile &&
          userProfile?.documents
            .filter((doc) => doc.tobesigned)
            .map((doc) => (
              <Link
                marginBottom={1}
                key={doc.id}
                style={{ cursor: "pointer" }}
                onClick={() => handleDownload(userProfile, doc)}
              >
                Scarica {doc.description}
              </Link>
            ))}
      </Box>
    </>
  );
}
